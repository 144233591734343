.form-container{
    display: flex;
    background-color: rgb(229, 229, 229);
    width: 100%;
    height: 100%;
}

.form-cotent{
    margin:auto;
}

.form-header{
    margin-top: 1rem;
}

.form-input-container{
    display: flex;
}

.itsDataListDiv{
    /* background-color: white; */
    padding: 1em;
    border-radius: 4px;
}

.text-note{
    padding-bottom: 1em;
}

.entry-form,
.past-entries {
    padding: 1em;
    border-radius: 4px;
    box-shadow: 1px 1px 1px 1px #ddd;
    margin-bottom: 1em;
}

.entry-form{
    background-color: #e9f3ff;
}

.past-entries{
    background-color: #ffffffc7;
}

.entry-form h3,
.past-entries h3 {
    border-bottom: 2px solid #002147;
    margin-bottom: 0.5em;
}

label{
    font-weight: 700;
}

.inputClass{
    width: 100%;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    padding: 5px;
}

.react-date-picker__wrapper{
    border: none;
}


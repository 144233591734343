.user-card {
  background-color: #f9f9f9;
  border: 0.5px solid rgb(189, 189, 189);
  box-shadow: 2px 2px 2px #c9c9c9;
  border-radius: 4px;
  margin-bottom: 0.4em;
}

.nav-container {
  width: 100%;
  color: #fff;
  align-items: center;
  /* cursor:pointer;  */
  margin: 10px;
}

.user-img {
  border: 0.5px solid #bdbdbd;
  max-height: 118px;
  box-shadow: 2px 2px 2px #ddd;
  margin: 1em;
}

.user-details {
  font-weight: 700;
  font-size: 14px;
  font-family: Inter;
  color: #00336d;
  line-height: 17px;
}

.user-details p {
  margin: 0;
  line-height: 1.5em;
}

.page-title {
  color: #002146;
  background-color: white;
  padding: 0.3em;
  border: 0.5px solid rgb(189, 189, 189);
  border-radius: 0.2em;
  box-shadow: 2px 2px 2px #c9c9c9;
  max-width: calc(100% - 120px);
}

.sidebar-content {
  color: "#000 !important";
  padding: "5px !important";
  border-bottom: "1px solid #000 !important";
}

.loader-content {
  display: flex;
  min-height: 50vh;
  justify-content: center;
  align-items: center;
}

.image-content {
  width: 60px;
  height: 80px;
  margin-left: 10px;
  border-radius: 25px;
}

.image-header {
  color: #ffffff;
  font-weight: 700;
  margin-right: 10px;
  margin-top: 10px;
}

.btn-download {
  display: block;
  position: absolute;
  top: 6px;
  right: 20px;
  background-color: rgb(0, 33, 71);
  text-decoration: none;
  color: #ffff;
  padding: 0.5em 1em;
  border-radius: 6px;
  box-shadow: 1px 1px 2px 2px #c7c7c7;
}

.btn-download:hover {
  background-color: rgb(7 48 95);
  color: #ffff;
}

.main-content {
  position: relative;
  min-height: 80vh;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.top-nav-link {
  display: inline-block;
  color: white;
  margin-top: 1em;
  text-decoration: none;
  background-color: #002147;
  padding: 0.6em 1em;
  border-bottom: 2px solid white;
  border-radius: 5px 5px 0 0;
}

.top-nav-link.active,
.top-nav-link:hover {
  color: #002147;
  background-color: white;
}

.top-nav-link:first-child {
  border-bottom-left-radius: 2px;
}

.top-nav-link:last-child {
  border-bottom-right-radius: 2px;
}

.explaination-box {
  padding: 1em;
  background-color: white;
  margin-bottom: 0.5em;
  border-radius: 4px;
  color: #555;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .sidebarMedia {
    display: none;
  }
}
@media only screen and (min-width: 600px) {
  nav.smallScreen {
    display: none;
  }
}
.btn-sort {
  margin: 1em 0.4em 0.4em 0.4em;
  border: 2px solid #01336d !important;
  color: #00336d !important;
}

.btn-sort-active {
  margin: 1em 0.4em 0.4em 0.4em;
  border: 2px solid #01336d !important;
  color: #fff !important;
  background-color: #01336d !important;
}

.btn-get {
  font-size: 0.8em !important;
  min-width: 8em;
  line-height: 1.2em !important;
}

.input-get {
  line-height: 1.2em !important;
  font-size: 0.8em !important;
}

.btn-sort-icon {
  margin-left: 1em;
}

.label {
  font-weight: bold;
}

.primary-bg {
  background-color: #9cc08e;
  padding: 0.5em;
  border-radius: 0.25em;
}

.primary-bg > .primary-label {
  color: #fff;
  font-weight: bold;
  font-size: 1.2em;
  padding-top: 0.3em;
  border-bottom: 2px solid #ccc;
}

.primary-bg > .menu-item {
  width: 100%;
  color: #bbb;
  padding: 5px;
  margin-bottom: 4px;
  cursor: pointer;
  border-bottom: 1px solid #333;
  font-size: 0.85em;
}

.menu-active {
  color: white !important;
  font-size: 1em !important;
}

.btn-block {
  width: 100%;
}

.btn-primary {
  background-color: #01336d !important;
  border-color: none;
}

.show-less {
  position: relative;
  max-height: 55px;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}

.show-less:hover {
  max-height: 500px;
  transition: max-height 0.5s ease-in;
}

.show-less::after {
  position: absolute;
  bottom: 4px;
  right: 4px;
  content: "⌄";
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #315a23;
  color: white;
  font-weight: 900;
  font-size: 1em;
  overflow: visible;
  text-align: center;
}

.show-less:hover::after {
  content: "⌃";
  font-weight: 900;
  font-size: 1em;
}

.lbl-sidebar {
  width: 100%;
  background: rgb(237, 237, 237);
  color: rgb(0, 0, 0);
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 4px;
  cursor: pointer;
  font-size: 0.85em;
}

.unauthorisedScreen {
  background-color: #bdbdbd;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.unauthorisedMessage {
  background-color: #002147;
  color: #000;
  border-radius: 1rem;
  max-width: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 8px #696969;
}

.unauthorisedMessage > img {
  padding: 0.6rem 0;
}

.unauthorisedMessage > div > div > h1 {
  font-size: 1.8rem;
  text-align: center;
}

.unauthorisedMessage > div > div > h2 {
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.6px;
}

.unauthorisedMessage > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 1rem 2rem;
}

.unauthorisedMessage > div > a {
  text-decoration: none;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  background-color: #002147;
  padding: 0.6rem 1rem;
  border-radius: 1rem;
}

.unauthorisedMessageLight {
  border-radius: 1rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: #fff;
}

@media only screen and (max-width: 800px) {
  .unauthorisedMessage {
    max-width: 80vw;
  }
  .unauthorisedMessage > div > h1 {
    font-size: 1.4rem;
  }
  .unauthorisedMessage > div > h2 {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 600px) {
  .unauthorisedMessage {
    max-width: 90vw;
  }
  .unauthorisedMessage > div > h1 {
    font-size: 1.4rem;
  }
  .unauthorisedMessage > div > h2 {
    font-size: 1rem;
  }
}
